<template src="../views/App.html"></template>

<script>

  export default {
    name: 'app',
    data () { return {
      myData: 'qwe'
    }; },
    mounted () {
    }
  };

</script>